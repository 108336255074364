import { Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import ColorHeading from "../../components/ColorHeading";
import MonthlyPlan from "./Cards/MonthlyPlan";
import CustomPlan from "./Cards/CustomPlan";
function Pricing() {
  const theme = useTheme();
  const desktop = useMediaQuery("(min-width: 1024px)"); // we add spacings here

  return (
    <Stack
      className="Pricing"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        gap: "64px",
        padding: "100px 0 ",
        [theme.breakpoints.down("desktop")]: {},
        [theme.breakpoints.down("tablet")]: { width: "100%" },
      }}
    >
      <Stack
        sx={{
          [theme.breakpoints.down("desktop")]: {},
          [theme.breakpoints.down("tablet")]: {
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <ColorHeading
          text2="Flexible"
          text3=" Plans for Every Need"
          description="Choose the right solution for your business, whether you need ongoing support or a custom project approach."
        />
      </Stack>
      {/* Card */}
      <Stack
        sx={{
          alignItems: "center",
          justifyContent: "center",
          gap: "32px",
        }}
      >
        <MonthlyPlan />
        <CustomPlan />
      </Stack>
    </Stack>
  );
}

export default Pricing;
