import { IconButton, Stack, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import LinkButton from "../../components/LinkButton";
import ZButton from "../../components/ZButton";
import { motion } from "framer-motion";
// import { LOGO_HEADER_PNG } from "./assets";
import LOGO_HEADER_PNG from "./assets/logos/LogoHeader.png";
import { Close } from "@mui/icons-material";
import IC_MENU from "./assets/icons/IC_MENU";

let lastUpdate = 0;
function Header() {
  const [stick, setStick] = useState(window.scrollY > 150);
  function handleScroll() {
    if (new Date().getTime() - lastUpdate > 50) {
      setStick(Math.ceil(window.scrollY) > 150);
      lastUpdate = new Date().getTime();
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const [toggled, setToggled] = useState(false);
  const desktop = useMediaQuery("(min-width: 1024px)"); // we add spacings here
  const theme = useTheme();
  return (
    <Stack
      sx={{
        transition: "all .7s",
        top: 0,
        left: 0,
        right: 0,
        alignItems: "center",
        justifyContent: "center",
        zIndex: "4",
        position: "fixed",
        background: stick ? "#000" : "transparent",
        flexWrap: "wrap",
        borderRadius: "0 0 10px 10px",
      }}
    >
      <Stack
        sx={{
          width: "100%",
          maxWidth: "1280px",
          padding: "24px 32px",
          borderRadius: "24px",
          flexWrap: "wrap",
          [theme.breakpoints.down("desktop")]: {},
          [theme.breakpoints.down("tablet")]: {
            background: "#0D0D0D",
            alignItems: "center",
            gap: "20px",
            borderRadius: " 0 0 24px 24px",
          },
        }}
      >
        <Stack
          sx={{
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            [theme.breakpoints.down("desktop")]: {},
            [theme.breakpoints.down("tablet")]: {
              width: "100%",
              flexWrap: "nowrap",
              justifyContent: "space-between",
              alignItems: "center",
            },
          }}
        >
          <Stack
            sx={{
              width: "132px",
              height: "32px",
              [theme.breakpoints.down("desktop")]: {},
              [theme.breakpoints.down("tablet")]: {
                width: "100px",
                height: "32px",
              },
            }}
          >
            <img
              src={LOGO_HEADER_PNG}
              alt=""
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </Stack>
          {desktop && (
            <Stack
              sx={{
                flexDirection: "row",
                padding: "12px 16px 12px 12px",
                alignItems: "center",
                background: "#0D0D0D",
                borderRadius: "40px",
                gap: "32px",
                backdropFsilter: "blur(8px)",
                border: "1px solid #2E2E2E",
                flexWrap: "wrap",
              }}
            >
              <LinkButton
                label="Home"
                observable=".hero-section"
                onClick={() => {
                  const _elem = window.document.querySelector(".hero-section");
                  if (_elem) {
                    _elem.scrollIntoView({ behavior: "smooth" });
                  } else {
                    window.open("/");
                  }
                }}
              />
              <LinkButton
                label="Services"
                observable=".Services"
                onClick={() => {
                  const _elem = window.document.querySelector(".Services");
                  if (_elem) {
                    _elem.scrollIntoView({ behavior: "smooth" });
                  } else {
                    window.open("/");
                  }
                }}
              />
              <LinkButton
                observable=".Pricing"
                label="Pricing"
                onClick={() => {
                  const _elem = window.document.querySelector(".Pricing");
                  if (_elem) {
                    _elem.scrollIntoView({ behavior: "smooth" });
                  } else {
                    window.open("/");
                  }
                }}
              />

              <LinkButton
                label="Contact"
                observable=".contact"
                onClick={() => {
                  const _elem = window.document.querySelector(".contact");
                  if (_elem) {
                    _elem.scrollIntoView({ behavior: "smooth" });
                  } else {
                    window.open("/");
                  }
                }}
              />
              <LinkButton
                label="About Us"
                observable=".about"
                onClick={() => {
                  const _elem = window.document.querySelector(".about");
                  if (_elem) {
                    _elem.scrollIntoView({ behavior: "smooth" });
                  } else {
                    window.open("/");
                  }
                }}
              />
            </Stack>
          )}
          {desktop && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2 }}
            >
              <ZButton
                label="Request a Quote"
                onClick={() => {
                  const _elem = window.document.querySelector(".contact");
                  if (_elem) {
                    _elem.scrollIntoView({ behavior: "smooth" });
                  } else {
                    window.open("/");
                  }
                }}
              />
            </motion.div>
          )}
          {/* Mobile friendly */}
          {!desktop && (
            <IconButton onClick={() => setToggled(!toggled)}>
              {!toggled && <IC_MENU />}
              {toggled && <Close />}
            </IconButton>
          )}
        </Stack>
        {/* Mobile friendly */}
        {toggled && (
          <Stack
            sx={{
              alignItems: "center",
              // background: "#0D0D0D",
              // borderRadius: "16px",
              // border: "1px solid #2E2E2E",
              flexWrap: "wrap",
              padding: "0",
              gap: "7px",
              backdropFsilter: "blur(8px)",
            }}
          >
            <LinkButton
              label="Home"
              observable=".hero-section"
              onClick={() => {
                const _elem = window.document.querySelector(".hero-section");
                if (_elem) {
                  _elem.scrollIntoView({ behavior: "smooth" });
                } else {
                  window.open("/");
                }
              }}
            />
            <LinkButton
              label="Services"
              observable=".Services"
              onClick={() => {
                const _elem = window.document.querySelector(".Services");
                if (_elem) {
                  _elem.scrollIntoView({ behavior: "smooth" });
                } else {
                  window.open("/");
                }
              }}
            />
            <LinkButton
              observable=".Pricing"
              label="Pricing"
              onClick={() => {
                const _elem = window.document.querySelector(".Pricing");
                if (_elem) {
                  _elem.scrollIntoView({ behavior: "smooth" });
                } else {
                  window.open("/");
                }
              }}
            />
            <LinkButton
              label="Contact"
              observable=".contact"
              onClick={() => {
                const _elem = window.document.querySelector(".contact");
                if (_elem) {
                  _elem.scrollIntoView({ behavior: "smooth" });
                } else {
                  window.open("/");
                }
              }}
            />
            <LinkButton
              label="About Us"
              observable=".about"
              onClick={() => {
                const _elem = window.document.querySelector(".about");
                if (_elem) {
                  _elem.scrollIntoView({ behavior: "smooth" });
                } else {
                  window.open("/");
                }
              }}
            />
          </Stack>
        )}
        {toggled && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2 }}
          >
            <ZButton
              label="Request a Quote"
              onClick={() => {
                const _elem = window.document.querySelector(".contact");
                if (_elem) {
                  _elem.scrollIntoView({ behavior: "smooth" });
                } else {
                  window.open("/");
                }
              }}
            />
          </motion.div>
        )}
      </Stack>
    </Stack>
  );
}

export default Header;
