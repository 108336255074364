import React from "react";
import { useState } from "react";
import { Stack } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";

export default function PageSplashScreen({ children }: any) {
  const [initialized] = useState(true);
  // const [profile, setProfile] = useRecoilState(aProfile);

  // async function loadProfile() {
  //   try {
  //     const prof = await rtmGetProfile();
  //     setProfile(prof);
  //     console.log(prof);
  //   } catch (err: any) {
  //     console.log(err);
  //   }
  // }

  // useEffect(() => {
  //   FbAuth.authStateReady().then(async () => {
  //     if (FbAuth.currentUser) {
  //       await ensureAuthentication();
  //       await loadProfile();
  //     }
  //     setInitialized(true);
  //   });
  // }, []);

  if (initialized) {
    return <>{children}</>;
  }

  return (
    <Stack sx={{ height: "100vh" }} className="scaling-element">
      <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
        <Player
          autoplay
          loop
          src="https://lottie.host/34249faf-c0ab-43d9-a72c-abf0272a7643/HE11ilgVOu.json"
          style={{ height: "380px" }}
        />
      </Stack>
    </Stack>
  );
}
