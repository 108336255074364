import { Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import Header from "./Header";
import Hero from "./Hero";
import Features from "./Features";
import FeaturesNum from "./FeaturesNum";
import Services from "./Services";
import Pricing from "./Pricing";
import Testimonials from "./Testimonials";
import Contact from "./Contact";
import Network from "./Network";
import FAQ from "./Faq";
import CtaSection from "./CtaSection";
import Footer from "./Footer";
function Index() {
  const desktop = useMediaQuery("(min-width: 1024px)"); // we add spacings here
  const theme = useTheme();

  return (
    <Stack>
      <Header />
      <Stack
        sx={{
          background: "#000",
        }}
      >
        <Hero />
      </Stack>
      <Features />
      <FeaturesNum />
      <Services />
      <Pricing />
      <Testimonials />
      <Contact />
      {desktop && <Network />}
      {/* <GetStart /> */}
      <FAQ />
      <Stack
        sx={{
          padding: "350px 0px 0px 0px",
          background: "#000",
          [theme.breakpoints.down("desktop")]: {},
          [theme.breakpoints.down("tablet")]: {
            padding: "0",
          },
        }}
      >
        <CtaSection />
        {/* <LogosSlider /> */}
        <Footer />
      </Stack>
    </Stack>
  );
}

export default Index;
