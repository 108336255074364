import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";

function BookCall() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));
  return (
    <iframe
      title="Book a Call"
      src="https://cal.com/zexware/30min"
      height="600px"
      width={isMobile ? "100%" : "1000px"}
      style={{
        border: "none",
        borderRadius: "12px",
      }}
    ></iframe>
  );
}

export default BookCall;
