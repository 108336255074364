import {
  Alert,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  Grid,
  IconButton,
  useTheme,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { enqueueSnackbar, SnackbarProvider } from "notistack";
// import { motion } from "framer-motion";
import ZCheckbox from "../../../components/ZCheckbox";
import ZInput from "../../../components/ZInput";
import ZButton from "../../../components/ZButton";
import { SendWebhookMessage } from "../../../core/helper";
import { AppConfig } from "../../../config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FbStorage } from "../../../core/firebase";
import { Close } from "@mui/icons-material";
export default function Contact_Form() {
  // Budget
  interface Budget {
    label: string;
    value: string;
  }
  const budgets: Budget[] = [
    { label: "<$5k", value: "<$5k" },
    { label: "$5k-$8k", value: "$5k-$8k" },
    { label: "$10k+", value: "$10k+" },
  ];
  const SERVICES = [
    "Bot Development",
    "Web App Development",
    "Extension Development",
    "Desktop Development",
    "Website Development",
    "API Development",
  ];
  const [selectedBudget, setSelectedBudget] = useState<Budget["value"] | null>(
    null
  );
  const [submitting, setSubmitting] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [agreement, setAgreement] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const desktop = useMediaQuery("screen and (min-width: 710px)");
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedServices, setSelectedServices] = useState<{
    [key: string]: boolean;
  }>({});

  const inputRef = useRef<HTMLInputElement>(null);

  async function uploadFiles() {
    // Upload all files
    const _folder = new Date().getTime();
    const _folderRef = ref(FbStorage, "/uploads/" + _folder + "/");
    const _outputs = [];
    for (let _file of selectedFiles) {
      const _uploadRef = ref(_folderRef, _file?.name);
      const _data = await _file?.arrayBuffer();
      await uploadBytes(_uploadRef, _data!, {
        customMetadata: {
          email: email,
          name: name,
        },
      });
      const _link = await getDownloadURL(_uploadRef);
      _outputs.push(_link);
    }
    return _outputs;
  }

  async function SendForm() {
    try {
      setSubmitting(true);
      const _files = selectedFiles?.length! > 0 ? await uploadFiles() : [];

      let _services = "\nSelected Services: \n";
      for (let _service of Object.keys(selectedServices)) {
        _services += `✅ ${_service}\n`;
      }

      let _attaches = "Attachments: \n";
      for (let _file of _files) {
        _attaches += "📁 - " + _file + "\n";
      }

      await SendWebhookMessage(
        AppConfig.discord.webhook,
        `Contact Form Submission from ${name}`,
        `${msg + _services + _attaches}`,
        0x0f0,
        [
          {
            name: "Full name",
            value: name,
            inline: true,
          },
          {
            name: "Email",
            value: email,
            inline: true,
          },
          {
            name: "Budget",
            value: selectedBudget,
            inline: true,
          },
        ],
        _files
      );
      enqueueSnackbar("Your message has been sent. ", { variant: "success" });
      setSubmitted(true);
    } catch (err: any) {
      enqueueSnackbar("Error submitting form. Please try again.", {
        variant: "error",
      });
    }
    setSubmitting(false);
  }
  const theme = useTheme();
  return (
    <Stack
      spacing={"32px"}
      sx={{
        bgcolor: "#fff3",
        width: "650px",
        [theme.breakpoints.down("desktop")]: {},
        [theme.breakpoints.down("tablet")]: {
          width: "90%",
          gap: "24px",
        },
      }}
    >
      {/* The contact form  */}
      <Stack
        spacing={"28px"}
        flex={1}
        sx={{
          borderRadius: "12px",
          color: "black",
        }}
      >
        <Stack
          spacing={"16px"}
          alignItems={"center"}
          direction={"row"}
          sx={{
            width: "100%",
            [theme.breakpoints.down("desktop")]: {},
            [theme.breakpoints.down("tablet")]: {
              width: "90%",
              flexDirection: "column",
              gap: "16px",
            },
          }}
        >
          <ZInput
            disabled={submitted || submitting}
            label="Full name"
            placeholder="Enter your full name..."
            text={name}
            onUpdate={(t: any) => setName(t)}
            sx={{
              width: "50%",
              [theme.breakpoints.down("desktop")]: {},
              [theme.breakpoints.down("tablet")]: {
                width: "100%",
              },
            }}
          />
          <ZInput
            disabled={submitted || submitting}
            label="Email"
            placeholder="your@company.com"
            text={email}
            onUpdate={(t: any) => setEmail(t)}
            sx={{
              width: "50%",
              [theme.breakpoints.down("desktop")]: {},
              [theme.breakpoints.down("tablet")]: {
                width: "100%",
              },
            }}
          />
        </Stack>
        <Stack spacing={"16px"}>
          <Typography sx={{ color: "#344054", fontWeight: 500 }}>
            Services you need{" "}
          </Typography>
          <Grid container columns={2}>
            {SERVICES.map((service) => (
              <Grid item xs={1}>
                <ZCheckbox
                  label={service}
                  value={selectedServices[service]}
                  onUpdate={(t) =>
                    setSelectedServices({
                      ...selectedServices,
                      [`${service}`]: t,
                    })
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Stack>
        {/* Budget */}
        <Stack spacing={"16px"}>
          <Typography sx={{ color: "#344054", fontWeight: 500 }}>
            Your Budget
          </Typography>
          <Stack
            sx={{
              display: "flex",
              gap: "10px",
              flexDirection: "row",
              [theme.breakpoints.down("desktop")]: {},
              [theme.breakpoints.down("tablet")]: {
                flexDirection: "column",
              },
            }}
          >
            {budgets.map((budget) => (
              <Button
                key={budget.value}
                onClick={() => setSelectedBudget(budget.value)}
                sx={{
                  minWidth: "189px",
                  padding: "12px 18px",
                  borderRadius: "16px",
                  cursor: "pointer",
                  backgroundColor:
                    selectedBudget === budget.value ? "#2E90FA" : "#",
                  color: selectedBudget === budget.value ? "#fff" : "#344054",
                  border: "1px solid #EAECF0",
                  "&:hover": {
                    backgroundColor:
                      selectedBudget === budget.value ? "#2E90FA" : "#fff",
                  },
                }}
              >
                {budget.label}
              </Button>
            ))}
          </Stack>
        </Stack>

        <ZInput
          disabled={submitted || submitting}
          label="Tell us about your project"
          multiline
          text={msg}
          onUpdate={(t: any) => setMsg(t)}
          rows={4}
          placeholder="Share your project details..."
        />

        <Stack>
          <Typography>Attach files</Typography>
          <Stack
            onClick={() => {
              inputRef.current?.click();
            }}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              py: "28px",
              px: "24px",
              borderRadius: "12px",
              border: "1px solid #EAECF0",
              cursor: "pointer",
            }}
          >
            <Typography color="#175CD3">Click to upload</Typography>
            <input
              ref={inputRef}
              style={{
                position: "absolute",
                zIndex: -100,
                opacity: 0,
              }}
              type="file"
              multiple={false}
              onChange={(e) => {
                if (e.currentTarget.files) {
                  setSelectedFiles([
                    ...selectedFiles,
                    e.currentTarget.files.item(0)!,
                  ]);
                }
              }}
            />
          </Stack>

          {selectedFiles.map((sf, ind) => (
            <Stack direction={"row"} alignItems={"center"}>
              <Typography sx={{ flex: 1 }}>
                {sf.name} - {Math.ceil(sf.size / 1024)}KB
              </Typography>
              <IconButton
                color="error"
                onClick={() => {
                  selectedFiles.splice(ind, 1);
                  setSelectedFiles([...selectedFiles]);
                }}
              >
                <Close />
              </IconButton>
            </Stack>
          ))}
        </Stack>

        <ZCheckbox
          typographySx={{ fontSize: desktop ? 14 : 12 }}
          value={agreement}
          onUpdate={(t: any) => setAgreement(t)}
          label="I agree to the Terms & Conditions and Privacy Policy."
        />
        <SnackbarProvider maxSnack={1} autoHideDuration={2000}>
          {!submitted && (
            <ZButton
              sx={{
                color: "#fff",
                padding: "20px 28px",
                fontSize: "16px",
                fontWeight: "600",
              }}
              disabled={submitting || !agreement}
              onClick={SendForm}
              label={"Submit a request"}
            />
          )}
        </SnackbarProvider>
        {submitted && <Alert>Your message has been sent.</Alert>}
      </Stack>
    </Stack>
  );
}
