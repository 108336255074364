import * as React from "react";

const LogoQuantify: React.FC<React.SVGProps<SVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="128"
    height="36"
    fill="none"
    viewBox="0 0 128 36"
  >
    <mask
      id="qfx"
      width="128"
      height="36"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: "alpha" }}
    >
      <path
        fill="#000"
        d="M39.733 25.724h-3.2V24.18c-.795 1.035-2.18 1.697-3.746 1.697-2.928 0-5.04-1.85-5.04-5.198v-7.157h3.178v6.718c0 1.96 1.114 3.018 2.792 3.018 1.726 0 2.816-1.058 2.816-3.018v-6.718h3.2zM47.791 13.323c2.02 0 3.406.925 4.154 1.938v-1.74h3.2v12.203h-3.2V23.94c-.75 1.057-2.18 1.982-4.177 1.982-3.178 0-5.72-2.534-5.72-6.344s2.542-6.255 5.743-6.255m.816 2.709c-1.702 0-3.314 1.233-3.314 3.546s1.612 3.634 3.314 3.634c1.749 0 3.338-1.277 3.338-3.589s-1.59-3.591-3.338-3.591M67.062 18.984c0-1.96-1.111-3.018-2.792-3.018-1.725 0-2.836 1.057-2.836 3.018v6.74h-3.179V13.52h3.179v1.52c.818-1.036 2.18-1.697 3.79-1.697 2.928 0 5.017 1.85 5.017 5.22v7.158h-3.179zM73.76 16.054H72.26v-2.533h1.498v-3.018h3.2v3.018h2.815v2.533H76.96v5.903c0 .814.34 1.167 1.339 1.167h1.475v2.6h-1.998c-2.405 0-4.017-.992-4.017-3.79v-5.88zM81.727 10.261c0-1.012.817-1.806 1.952-1.806 1.113 0 1.953.794 1.953 1.806s-.84 1.806-1.953 1.806c-1.136 0-1.952-.793-1.952-1.806m.34 3.26h3.178v12.202h-3.178zM88.717 16.054h-1.43v-2.533h1.43v-.617c0-2.995 1.749-4.383 5.289-4.294v2.6c-1.543-.044-2.066.44-2.066 1.805v.507h2.202v2.534H91.94v9.669h-3.223zM104.856 13.521h3.383l-7.787 17.973H97.07l2.724-6.08-5.04-11.893h3.565l3.246 8.525z"
      ></path>
      <path
        fill="#231F20"
        d="M14.176 4.5c-6.037 0-10.93 4.748-10.93 10.605 0 5.202 3.86 9.53 8.954 10.433v-3.411c-3.183-.842-5.523-3.666-5.523-7.022 0-4.019 3.357-7.276 7.499-7.276s7.499 3.257 7.499 7.276a7.1 7.1 0 0 1-.96 3.566l2.489 2.415a10.34 10.34 0 0 0 1.901-5.98c0-5.857-4.893-10.606-10.93-10.606"
      ></path>
      <path
        fill="#231F20"
        d="M14.191 25.697V15.092l10.9 10.55h-4.824l-2.647-2.569v2.624z"
      ></path>
    </mask>
    <g mask="url(#qfx)">
      <path fill="#B0B0B0" d="M-29-16h182v64H-29z"></path>
    </g>
  </svg>
);

export default LogoQuantify;
