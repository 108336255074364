import { Stack, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import { motion } from "framer-motion";
import ColorHeading from "../../components/ColorHeading";
import ZButton from "../../components/ZButton";
import ZAcc from "../../components/ZAccordion";
import { IMG_BACKGROUND_EFFECT } from "./assets/images";
import IC_PHONE from "./assets/icons/IC_PHONE";

export default function FAQ() {
  const theme = useTheme();
  const desktop = useMediaQuery("(min-width: 1024px)"); // we add spacings here
  const [openFaq, setOpenFaq] = useState(0);

  return (
    <Stack
      sx={{
        alignItems: "center",
        // padding: "100px 0",
        position: "relative",
        // overflow: "hidden",
        paddingTop: "100px",
        height: "100%",
        [theme.breakpoints.down("desktop")]: {},
        [theme.breakpoints.down("tablet")]: {
          paddingBottom: "100px",
        },
      }}
    >
      <Stack
        sx={{
          maxWidth: "1280px",
          width: "100%",
          gap: "136px",
          zIndex: "2",
          flexDirection: "row",
          [theme.breakpoints.down("desktop")]: {},
          [theme.breakpoints.down("tablet")]: {
            flexDirection: "column",
            gap: "12px",
            padding: "0 10px",
          },
        }}
      >
        <Stack
          sx={{
            alignItems: "flex-start",
            [theme.breakpoints.down("desktop")]: {},
            [theme.breakpoints.down("tablet")]: {
              alignItems: "center",
            },
          }}
        >
          {/*faq text with botton */}
          <Stack
            spacing={"16px"}
            sx={{
              [theme.breakpoints.down("desktop")]: {},
              [theme.breakpoints.down("tablet")]: {
                alignItems: "center",
              },
            }}
          >
            <ColorHeading
              mainsx={{
                width: "500px",
                textAlign: "start",
                [theme.breakpoints.down("desktop")]: {},
                [theme.breakpoints.down("tablet")]: {
                  width: "100%",
                  alignItems: "center",
                  textAlign: "center",
                },
              }}
              text1="Frequently Asked "
              text2="Questions"
              description="We’re here to help! If you didn’t find the answer you were looking for, feel free to reach out to our team for personalized support."
              desSx={{ textAlign: "start" }}
            />
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2 }}
            >
              <ZButton
                label={"Get a Free Consultation"}
                endIcon={<IC_PHONE />}
                onClick={() => {
                  const _elem = window.document.querySelector(".contact");
                  if (_elem) {
                    _elem.scrollIntoView({ behavior: "smooth" });
                  } else {
                    window.open("/");
                  }
                }}
              />
            </motion.div>
          </Stack>
        </Stack>
        <Stack
          sx={{
            gap: "20px",
            [theme.breakpoints.down("desktop")]: {},
            [theme.breakpoints.down("tablet")]: { alignItems: "center" },
          }}
        >
          <ZAcc
            onChange={(e) => setOpenFaq(e ? 0 : -1)}
            active={openFaq === 0}
            title="How does Zexware approach custom software development?"
            text="We work closely with our clients to understand their unique needs, ensuring that each solution is tailored, scalable, and aligned with their business goals."
          />
          <ZAcc
            onChange={(e) => setOpenFaq(e ? 1 : -1)}
            active={openFaq === 1}
            title="What services does Zexware provide?"
            text="At Zexware, we provide custom Desktop, Web, and Mobile App development services as well as development of bots for various platforms, such as Telegram and Discord, all tailored to fit your business needs."
          />
          <ZAcc
            onChange={(e) => setOpenFaq(e ? 2 : -1)}
            active={openFaq === 2}
            title="Can you integrate third-party tools into existing systems?"
            text="Of course! With custom development come third-party integrations, including most popular online services such as Stripe, Livechat.com, and any custom APIs with RESTful interface."
          />
          <ZAcc
            onChange={(e) => setOpenFaq(e ? 3 : -1)}
            active={openFaq === 3}
            title="What industries does Zexware work with?"
            text="Zexware works closely with trading and prop firm industries, as well as helpdesk industries that provide support solutions to companies using our AI-powered support solutions deeply integrated with third-party existing helpdesk services. "
          />
          <ZAcc
            onChange={(e) => setOpenFaq(e ? 4 : -1)}
            active={openFaq === 4}
            title="How do I get started with Zexware?"
            text="Reach out to us via our contact form, or schedule a call so we can discuss your project and ideas. "
          />
        </Stack>
      </Stack>
      {desktop && (
        <Stack
          sx={{
            position: "absolute",
            zIndex: "1",
            width: "100%",
            transform: "rotate(179.51deg)",
            pointerEvents: "none",
            overflow: "hidden",
            bottom: "-240px",
            left: "0",
            right: "0",
          }}
        >
          <img
            src={IMG_BACKGROUND_EFFECT}
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </Stack>
      )}
    </Stack>
  );
}
