import { Stack, useTheme } from "@mui/material";
import React from "react";
import ColorHeading from "../../components/ColorHeading";
import ServicesCard from "./Cards/ServicesCard";
import IC_BOT_DEV from "./assets/icons/IC_BOT_DEV";
import IC_WEB_DEV from "./assets/icons/IC_WEB_DEV";
import IC_DESKTOP_DEV from "./assets/icons/IC_DESKTOP_DEV";
import IC_MOB_DEV from "./assets/icons/IC_MOB_DEV";
import IC_EXTENSION_DEV from "./assets/icons/IC_EXTENSION_DEV";
import IC_API_DEV from "./assets/icons/IC_API_DEV";

export default function Services() {
  const theme = useTheme();

  return (
    <Stack
      className="Services"
      alignItems={"center"}
      sx={{
        padding: "0px 0px 100px 0px",
        gap: "72px",
        [theme.breakpoints.down("desktop")]: {},
        [theme.breakpoints.down("tablet")]: {},
      }}
    >
      {/* heading */}
      <Stack
        sx={{
          width: "800px",
          [theme.breakpoints.down("desktop")]: {},
          [theme.breakpoints.down("tablet")]: {
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <ColorHeading
          text1="Our Expert Services: "
          text2="Tailored for Your Success"
          description="At Zexware, we provide a wide range of digital services designed to elevate your business. From software development to seamless integrations, we ensure every solution is customized to meet your specific needs. Explore our 8 core services below"
        />
      </Stack>
      <Stack sx={{ gap: "72px" }}>
        <Stack
          sx={{
            flexDirection: "row",
            gap: "32px",
            [theme.breakpoints.down("desktop")]: {},
            [theme.breakpoints.down("tablet")]: {
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
        >
          <ServicesCard
            img={<IC_BOT_DEV />}
            heading="Bot Development"
            description="Create custom Discord, WhatsApp, and Telegram bots to automate tasks and enhance user engagement across platforms."
          />
          <ServicesCard
            img={<IC_WEB_DEV />}
            heading="Web App Development"
            description="Build fast, scalable web applications using React, Next.js, and TailwindCSS to meet modern business needs."
          />
          <ServicesCard
            img={<IC_DESKTOP_DEV />}
            heading="Desktop Development"
            description="CDevelop powerful desktop applications using .NET, ElectronJS, and React for cross-platform efficiency."
          />
        </Stack>
        <Stack
          sx={{
            flexDirection: "row",
            gap: "32px",
            [theme.breakpoints.down("desktop")]: {},
            [theme.breakpoints.down("tablet")]: {
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
        >
          <ServicesCard
            img={<IC_MOB_DEV />}
            heading="Mobile App Development"
            description="Create feature-rich mobile applications with ReactNative and Android Native (Xamarin) for seamless user experiences."
          />
          <ServicesCard
            img={<IC_EXTENSION_DEV />}
            heading="Extension Development"
            description="Develop browser extensions for Microsoft Edge and Google Chrome to expand your software’s capabilities."
          />
          <ServicesCard
            img={<IC_API_DEV />}
            heading="API Development"
            description="Build secure, scalable APIs with NodeJS, REST API, GraphQL, and WebSockets to power your digital ecosystem."
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
