import { Button, SxProps, useTheme } from "@mui/material";
import React from "react";

function ZButton(props: {
  label?: any;
  sx?: SxProps;
  endIcon?: any;
  startIcon?: any;
  mainIcon?: any;
  onClick?: any;
  disabled?: any;
  href?: string;
}) {
  const theme = useTheme();
  return (
    <Button
      href={props.href}
      sx={{
        fontWeight: "600",
        textTransform: "none",
        padding: "10px 16px",
        color: "#fff",
        fontSize: "16px",
        borderRadius: "16px",
        background: theme.palette.grey[300],
        border: "1.2px solid #1570EF",
        /* Shadows/shadow-xs */
        boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        ":hover": {
          color: "#fff",
          background: theme.palette.grey[100],
          border: "1.2px solid #1570EF",
        },

        ...props.sx,
      }}
      endIcon={props.endIcon}
      startIcon={props.startIcon}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.label}
      {props.mainIcon}
    </Button>
  );
}

export default ZButton;
