import * as React from "react";

const LogoUptone: React.FC<React.SVGProps<SVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="57"
    height="41"
    fill="none"
    viewBox="0 0 57 41"
  >
    <mask
      id="uptoned"
      width="57"
      height="38"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: "alpha" }}
    >
      <path
        fill="#fff"
        d="M49.408 32.253c-1.786-2.36-4.028-3.555-6.935-2.723-2.782.796-4.228 2.788-4.458 5.738h-8.732V.006c.888 0 1.726-.015 2.561.003 3.491.068 7.026-.185 10.464.29 8.725 1.195 14.763 8.645 14.648 17.588-.074 5.777-2.361 10.411-6.893 13.921-.198.147-.41.278-.656.445m-12.187-4.22c4.084.537 7.766-1.321 9.752-4.85 2.122-3.769 1.788-8.958-.797-12.304-2.139-2.773-6.072-4.318-8.958-3.51.003 6.865.003 13.736.003 20.664M7.954 5.896c0 4.831-.036 9.66.02 14.49.018 1.477.174 2.97.472 4.414.372 1.796 1.567 2.976 3.373 3.245a12.2 12.2 0 0 0 3.609.003c2.071-.322 3.187-1.78 3.526-3.779.227-1.348.319-2.731.325-4.1.032-6.174.015-12.344.015-18.517V.286h7.875c.033.206.089.379.086.552-.012 7.846.059 15.692-.08 23.536-.077 4.333-1.703 7.986-5.713 10.125-3.065 1.634-6.391 1.857-9.773 1.6-2.222-.169-4.36-.661-6.305-1.812-3.538-2.094-5.105-5.443-5.28-9.373-.203-4.6-.047-9.213-.047-13.898 3.3-.59 5.855-2.374 7.897-5.12"
      ></path>
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth="0.178"
        d="M44.059 32.953c.732-.004 1.354.252 1.79.688.437.435.694 1.053.688 1.781-.011 1.358-1.09 2.466-2.415 2.489-1.38.02-2.441-1.075-2.435-2.53.002-.738.24-1.342.651-1.762.41-.42 1-.663 1.721-.666Z"
      ></path>
    </mask>
    <g mask="url(#uptoned)">
      <path fill="#B0B0B0" d="M-14-8h81v51h-81z"></path>
    </g>
  </svg>
);

export default LogoUptone;
